<template>
  <div id="LoadingPage" style="position: relative; height: 100vh">
    <div class="post-x" style="">
      <div class="wrap">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
      </div>

      <div class="fontsize-h5 text weight-500">
        ระบบอยู่ระหว่างการดาวน์โหลดข้อมูล<br />เพื่อให้ระบบสามารถดาวน์โหลดข้อมูล
        กรุณาอย่าปิดหน้านี้
      </div>
    </div>
  </div>
</template>

<script>
import { AssessmentService } from "@/services/main.service.js";
export default {
  name: "loading-page",
  data() {
    return {
      apiURL: null,
    };
  },
  created() {
    this.apiURL = process.env.VUE_APP_BASE_API_DOWNLOAD;
  },
  methods: {
    closeTab() {
      window.close();
    },
    async downloadExcel() {
      const param = {
        TypeWaterID: this.PWATypeSelect,
        DepID: this.departmentSelect,
        ProvinceID: this.proviceSelect,
        AmphorID: this.amphurSelect,
        TambolID: this.tambolSelect,
      };

      await AssessmentService.getExportExcel(param)
        .then((res) => {
          if (res) {
            const path = res.path;

            if (path && this.apiURL) {
              let file = `${this.apiURL}/${path}`;
              try {
                console.log(file);
                window.open(file, "_blank");
              } catch (err) {
                this.alertDownloadFail();
              }
            } else {
              this.alertDownloadFail();
            }
          }
        })
        .catch((err) => {
          this.alertDownloadFail();
        });
    },
  },
};
</script>

<style>
.wrap {
  width: 200px;
  height: 150px;
  margin: 0 auto;
  position: relative;
}
.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #2db4ad;
  left: 15%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
}

@keyframes circle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
}
.circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}
.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
}

@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}
.shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}
.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.text {
  /* position: absolute;
    width: 100%;
    top:75px;
    font-size: 20px;
    color: blue;
    left:15%; */
  line-height: 55px;
  width: 100%;
  text-align: center;
}
.post-x {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
